
<template>
   <div class="vs-row">
        <template lang="html">
          <div>
            <vx-card actionable class="cardx" title="Sop File">
              <vs-table  max-items="10" :data="users">
                  <!-- <template slot="header">
                      <h3 class="table_heading">
                        Reports
                      </h3>
                  </template> -->
                  <template slot="thead" class="exc" slot-scope="{data}">
                      <vs-th >
                      Level
                      </vs-th>
                      <vs-th>
                      Level Discription
                      </vs-th>
                      <vs-th>
                      Priority
                      </vs-th>
                      <vs-th>
                      Follow Up (Suggested Time Tag)
                      </vs-th>
                      <vs-th>
                      Follow Up (Maximum Time Tag)
                      </vs-th>
                  </template>

                  <template slot-scope="{data}">
                      <vs-tr :style="{backgroundColor: (data[indextr].level=='M6'||data[indextr].level=='M5'||data[indextr].level=='M3+'||data[indextr].level=='M3'? 'chartreuse' : 'transparent' && data[indextr].level=='M4'||data[indextr].level=='M2'||data[indextr].level=='N/A'||data[indextr].level=='OS'? '#d89177' : 'transparent'
                      && data[indextr].level=='L5'||data[indextr].level=='L4'||data[indextr].level=='L3+'||data[indextr].level=='L3'||data[indextr].level=='L2'? '#25d425' : 'transparent'&& data[indextr].level=='L4-'||data[indextr].level=='L2-'? '#f7ac039c' : 'transparent'&& data[indextr].level=='Total'? '#79e8e3' : 'transparent'
                       && data[indextr].level=='#N/A'||data[indextr].level=='BLANKS'? '#ffbc00' : 'transparent')}" :key="indextr" v-for="(tr, indextr) in data" >
                      <vs-td  :data="data[indextr].level" >
                          {{data[indextr].level}}
                      </vs-td>

                      <vs-td :data="data[indextr].level_description" >
                          {{data[indextr].level_description}}
                      </vs-td>

                      <vs-td :data="data[indextr].priority_level">
                          {{data[indextr].priority_level}}
                      </vs-td>

                      <vs-td :data="data[indextr].suggested_follow_up">
                          {{data[indextr].suggested_follow_up}}
                      </vs-td>

                      <vs-td :data="data[indextr].max_suggested_follow_up">
                          {{data[indextr].max_suggested_follow_up}}
                      </vs-td>

                      </vs-tr>
                  </template>
                </vs-table>  
            </vx-card>
          </div>  
        </template>   
             
    </div>
   
</template>
<script>
// import VueApexCharts from vue-apexcharts
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
export default {
    data:()=>({
    //   themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
      lineAreaChartSpline: {
        series: [{
            name: 'Leads',
            data: [31, 40, 28, 51, 42, 109, 100,31, 40, 28, 51, 42, 109, 100]
          }, {
            name: 'Opportunities',
            data: [11, 32, 45, 32, 34, 52, 41,31, 40, 28, 51, 42, 109]
        },{
            name: 'Accounts',
            data: [20, 35, 49, 34, 38, 54, 44]
        }],
        chartOptions: {
          dataLabels: {
            enabled: true
          },
          stroke: {
            // curve: 'smooth'
            width:[2,2,2]
          },
        //   colors: themeColors,
          xaxis: {
            type: 'datetime',
            categories: ["2018-09-19T00:00:00", "2018-09-19T01:30:00", "2018-09-19T02:30:00",
              "2018-09-19T03:30:00", "2018-09-19T04:30:00", "2018-09-19T05:30:00",
              "2018-09-19T06:30:00", "2018-09-19T07:30:00", "2018-09-19T08:30:00",
              "2018-09-19T09:30:00", "2018-09-19T10:30:00", "2018-09-19T11:30:00",
              "2018-09-19T12:30:00",
            ],
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },

          }
        }
      
    },
    leadsOverviewRadialBar: {
        leads: [50],
        opportunities: [50],
        accounts: [40],
        today: [40],
        chartOptions: {
            plotOptions: {
                radialBar: {
                    size: 45,
                    startAngle: -150,
                    endAngle: 150,
                    hollow: {
                        size: ' 70%',
                    },
                    track: {
                        background: "#bfc5cc",
                        strokeWidth: '70%',
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: 18,
                            color: '#99a2ac',
                            fontSize: '1.5rem'
                        }
                    }
                }
            },
            colors: ['#00db89'],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#00b5b5'],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100]
                },
            },
            stroke: {
                lineCap: 'round'
            },
            chart: {
                sparkline: {
                    enabled: true,
                },
                dropShadow: {
                    enabled: true,
                    blur: 3,
                    left: 1,
                    top: 1,
                    opacity: 0.1
                },
            },
        }
    },
    users:[
      {
        "level": "M6",
        "level_description": "Visited & Ready to Enroll",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      {
        "level": "M5",
        "level_description": "Visited & Possitive",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      {
        "level": "M4",
        "level_description": "Visited but refused/postponed",
        "priority_level": "No Follow Up",
        "suggested_follow_up": "90days",
        "max_suggested_follow_up": "180days"
      },
      {
        "level": "M3+",
        "level_description": "called & coming",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      {
        "level": "M3",
        "level_description": "called & possitive",
        "priority_level": "P1",
        "suggested_follow_up": "14days",
        "max_suggested_follow_up": "30days"
      },
      {
        "level": "M2",
        "level_description": "Did not Visit & Refused/Postponed",
        "priority_level": "No Follow Up",
        "suggested_follow_up": "90days",
        "max_suggested_follow_up": "180days"
      },
      {
        "level": "L5",
        "level_description": "Visited & Positivem(Last Batch) but did not Join",
        "priority_level": "P2",
        "suggested_follow_up": "15days",
        "max_suggested_follow_up": "60days"
      },
      {
        "level": "L4",
        "level_description": "Visited(Last Batch); but postponed & did not Join",
        "priority_level": "P2",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      {
        "level": "L4-",
        "level_description": "Visited (Last batch); 'NL/S.off' or 'Not Interested'",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      {
        "level": "L3+",
        "level_description": "Called & Coming (Last batch)",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      {
        "level": "L3",
        "level_description": "Called & Positive (Last Batch); but did not visit",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      {
        "level": "L2",
        "level_description": "Called (Last Batch); but did not visit & postponed",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      {
        "level": "L2-",
        "level_description": "Called (Last batch); 'NL/S.off' or 'Not Interested'",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      // {
      //   "level": "N/A",
      //   "level_description": "Visited but refused/postponed",
      //   "priority_level": "P1",
      //   "suggested_follow_up": "7days",
      //   "max_suggested_follow_up": "14days"
      // },
      // {
      //   "level": "OS",
      //   "level_description": "Visited but refused/postponed",
      //   "priority_level": "P1",
      //   "suggested_follow_up": "7days",
      //   "max_suggested_follow_up": "14days"
      // },
      {
        "level": "Total",
        "level_description": "Total",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      {
        "level": "#N/A",
        "level_description": "",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days"
      },
      {
        "level": "BLANKS",
        "level_description": "",
        "priority_level": "",
        "suggested_follow_up": "",
        "max_suggested_follow_up": "14days"
      },
    ]
  }),
  components: {
        VueApexCharts,
        StatisticsCardLine,
        ChangeTimeDurationDropdown
    },
}
</script>
<style>
.vs-radar-text{
    color: darkgray;
    position: relative;
    bottom: 70px;
    left: 35px;
    font-size: larger;
}
.radial_quarter{
    position: relative;
    height: 260px;
}
.lineAreaChartSpline{
    position: relative;
    height: 260px;
}
.vs-radar-thisquarter{
    color: darkgray;
    position: relative;
    bottom: 70px;
    font-size: larger;
    text-align: center;
}
.input-search{
      position: relative;
    right: 10px;
}
.table_heading{
  left: 20px;
  position: relative;
}
.vs-table--tbody-table tr{
  /* background-color:#ffbc00; */
  font-family: sans-serif;
  font-size: smaller;
  /* border: 1px solid black; */
  

}
/* .vs-radar-thisquarter-text{
    color: darkgray;
    position: relative;
    bottom: 40px;
    font-size: larger;
} */
</style>

